import { combineReducers } from 'redux';
import { configureStore } from "@reduxjs/toolkit";
import authReducers from './auth/authReducers';
import categoryReducers from './category/categoryReducers';
import recipeReducers from './recipe/recipeReducers';
import commonReducers from './common/commonReducers';
import roleReducers from './role/roleReducers';
import statusReducers from './status/statusReducers';

import storageSession from 'reduxjs-toolkit-persist/lib/storage/session';
import storage from 'reduxjs-toolkit-persist/lib/storage';

import {
    persistReducer,
    persistStore,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
} from 'redux-persist';

import { api } from './middleware/api/api';

const authPersistConfig = {
    key: 'auth',
    storage: storage
}

const categoryPersistConfig = {
    key: 'category',
    storage: storageSession
}

const recipePersistConfig = {
    key: 'recipe',
    storage: storageSession
}

const statusPersistConfig = {
    key: 'status',
    storage: storageSession
}

const rootReducer = combineReducers(
    {
        auth: persistReducer(authPersistConfig, authReducers),
        category: persistReducer(categoryPersistConfig, categoryReducers),
        recipe: persistReducer(recipePersistConfig, recipeReducers),
        status: persistReducer(statusPersistConfig, statusReducers),
        common: commonReducers,
        role: roleReducers,
    }
);

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }).concat(api),
    devTools: process.env.NODE_ENV !== 'production'
});

export const persistor = persistStore(store);