import produce from "immer";
import { FETCH_CATEGORY_LIST, FETCH_CATEGORY, CREATE_CATEGORY, UPDATE_CATEGORY, DELETE_CATEGORY } from "./categoryActionTypes";
import { intialState } from "./categoryState";


const categoryReducers = (state = intialState, action) => {
    const { type, payload } = action;
    return produce(state, draft => {
        switch (type) {
            case FETCH_CATEGORY_LIST:
                draft.list = payload ? payload.content : null;
                break;
            case FETCH_CATEGORY:
                draft.category = payload ? payload.content : null;
                break;
            case CREATE_CATEGORY:
                draft.category = payload ? payload.content : null;
                break;
            case UPDATE_CATEGORY:
                draft.category = payload ? payload.content : null;
                break;
            case DELETE_CATEGORY:
                draft.category = payload ? payload.content : null;
                break;
            default:

        }
    });
}

export default categoryReducers;