import React, { useEffect, useState } from 'react';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { DeleteCategoryModal } from '../../comps/category/delete-category-modal';

import { useDispatch, useSelector } from 'react-redux';
import { deleteCategory, fetchCategoryList } from '../../store/category/categoryActions';
import { fetchStatusList } from '../../store/status/statusActions';

const Category = () => {
    const navigate = useNavigate();
    const categories = useSelector(state => state.category.list);
    const statuses = useSelector(state => state.status.list);
    const [categoryToProcess, setCategoryToProcess] = useState(null);
    const [showDeleteCategoryModal, setShowDeleteCategoryModal] = useState(false);

    const loading = useSelector(state => state.common.loading);
    const dispatch = useDispatch();

    const [apiQuery, setApiQuery] = useState({
        skip: 0,
        limit: 100
    })

    useEffect(() => {
        dispatch(fetchCategoryList());
        dispatch(fetchStatusList());
    }, []);

    function onCreateCategory() {
        navigate("/new-category", {
            state: { statuses: statuses }
        });
    }

    function onEditCategory(category) {
        navigate("/edit-category", {
            state: { category: category, statuses: statuses }
        });
    }

    function onDeleteCategory(category) {
        setCategoryToProcess(category);
        setShowDeleteCategoryModal(true);
    }

    async function processDelete(id) {
        dispatch(deleteCategory(id));
        dispatch(fetchCategoryList(apiQuery));
        setShowDeleteCategoryModal(false);
    }

    return (
        <div className="flex flex-col justify-center items-center w-full">
            <div>
                <h2 className="text-cyan-800 text-lg font-medium">Category Page</h2>
                <button
                    onClick={() => onCreateCategory()}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    + CATEGORY
                </button>
            </div>
            <div className="flex flex-col justify-center items-start w-full pl-4 pr-4">
                {!loading && categories && categories.length > 0 && categories.map((category) => {
                    return (
                        <div
                            key={category._id}
                            className="flex justify-between items-start w-full p-4 bg-slate-100 rounded-lg mb-3"
                        >
                            <div className="w-2/3">
                                <div className="text-lg font-bold mb-2">
                                    {category.name}
                                </div>
                                <div
                                    className="flex flex-wrap justify-start items-center w-auto ml-4 mr-4"
                                >
                                    {category.subCategories.length && category.subCategories.map((sCat) => {
                                        return (
                                            <div
                                                key={sCat._id}
                                                className="pl-4 pr-4 pt-1 pb-1 ml-4 mr-4 bg-slate-300 rounded-lg"
                                            >
                                                {sCat.name}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="flex justify-center items-center w-auto">
                                <PencilIcon
                                    onClick={() => onEditCategory(category)}
                                    className="h-5 w-5 text-blue-500 cursor-pointer mr-3"
                                />
                                <TrashIcon
                                    onClick={() => onDeleteCategory(category)}
                                    className="h-5 w-5 text-red-500 cursor-pointer"
                                />
                            </div>
                        </div>
                    )
                })}
                {!loading && categories && categories.length < 1 &&
                    <div className="flex flex-col justify-center items-center w-full mt-3">
                        <h2>Category List is Empty!</h2>
                        <h3>Create New Category.</h3>
                    </div>
                }
            </div>
            <DeleteCategoryModal
                showDeleteCategoryModal={showDeleteCategoryModal}
                setShowDeleteCategoryModal={setShowDeleteCategoryModal}
                category={categoryToProcess}
                processDelete={processDelete}
            />

        </div>
    )
}

export { Category };
