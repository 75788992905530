import { FETCH_ROLE_LIST, FETCH_ROLE, CREATE_ROLE, UPDATE_ROLE, DELETE_ROLE } from "./roleActionTypes";

export const fetchRoleList = (query = { skip: 0, limit: 10 }) => async (dispatch, getState) => {
    const apiEndPoint = "role/all";
    const user = getState().auth.user;

    await dispatch(
        {
            type: FETCH_ROLE_LIST,
            payload: {
                apiEndPoint: apiEndPoint,
                authorizationHeader: user,
                data: { query },
                method: 'POST',
                successMsg: 'Successfully fetched roles !',
                errorMsg: 'Failed to fetch roles !'
            },
        }
    );
};

export const fetchRole = (id) => async (dispatch, getState) => {
    const apiEndPoint = "role/" + id;
    const user = getState().auth.user;

    dispatch({
        type: FETCH_ROLE,
        payload: {
            apiEndPoint: apiEndPoint,
            authorizationHeader: user,
            method: 'GET',
            successMsg: 'Successfully fetched role !',
            errorMsg: 'Failed to fetch role !'
        },
    });
};

export const createRole = (role) => async (dispatch, getState) => {
    const apiEndPoint = "role/new";
    const user = getState().auth.user;

    dispatch({
        type: CREATE_ROLE,
        payload: {
            apiEndPoint: apiEndPoint,
            authorizationHeader: user,
            data: { content: role },
            method: 'POST',
            successMsg: 'Successfully created role !',
            errorMsg: 'Failed to create role !'
        },
    });
};

export const updateRole = (role) => async (dispatch, getState) => {
    const { _id: id } = role;
    const apiEndPoint = "role/" + id;
    const user = getState().auth.user;

    dispatch({
        type: UPDATE_ROLE,
        payload: {
            apiEndPoint: apiEndPoint,
            authorizationHeader: user,
            data: { content: role },
            method: 'PUT',
            successMsg: 'Successfully updated role !',
            errorMsg: 'Failed to update role !'
        },
    });
};

export const deleteRole = (id) => async (dispatch, getState) => {
    const apiEndPoint = "role/" + id;
    const user = getState().auth.user;

    dispatch({
        type: DELETE_ROLE,
        payload: {
            apiEndPoint: apiEndPoint,
            authorizationHeader: user,
            method: 'DELETE',
            successMsg: 'Successfully deleted role !',
            errorMsg: 'Failed to delete role !'
        },
    });
};