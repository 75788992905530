import _ from 'underscore';

function isValidObject(obj) {
    if (_.isObject(obj)) {
        const keys = _.keys(obj);
        const values = _.values(obj);
        if (keys.length && values.length) {
            if (_.every(keys, function (key) {
                return isValidString(key);
            }) && _.every(values, function (val) {
                return (isValidString(val) || isValidNumber(val));
            })) {
                return true;
            }
        }
    }
    return false;
}

function isValidString(val) {
    if (_.isString(val)) {
        return val.length && !_.isNull(val) && !_.isUndefined(val);
    }
    return false;
}

function isValidNumber(val) {
    if (_.isNumber(val)) {
        return !_.isNaN(val) && !_.isNull(val) && !_.isUndefined(val);
    }
    return false;
}

export { isValidString, isValidNumber, isValidObject };