import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMsgInfo } from '../../store/common/commonActions';

const MessageModal = (props) => {
    const msgInfo = useSelector(state => state.common.msgInfo);
    const dispatch = useDispatch();

    useEffect(() => {
        if (msgInfo) {
            const timer = setTimeout(() => {
                closeMessageModal();
                clearTimeout(timer);
            }, 500);
        }
    })

    function closeMessageModal() {
        dispatch(setMsgInfo(null));
    }

    function getMessageTypeStyle(messageType) {
        if (messageType === "success") {
            return "bg-green-500";
        } else if (messageType === "error") {
            return "bg-red-500";
        } else if (messageType === "warning") {
            return "bg-amber-500";
        }

        return "bg-slate-50";
    }

    return (
        <div>
            {msgInfo ? (
                <>
                    <div
                        onClick={closeMessageModal}
                        className="w-full h-full bg-slate-400 bg-opacity-50 flex justify-center items-end overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className={`${getMessageTypeStyle(msgInfo.type)} w-auto flex justify-center items-center my-6 mx-auto p-2.5 rounded-md`}>
                            <h3 className="text-lg text-white font=semibold">{msgInfo.msg}</h3>
                        </div>
                    </div>
                </>) : null}
        </div>
    )
}

export { MessageModal };