import React, { useState, useEffect } from 'react';

const EditInstructionModal = (props) => {
    const { showEditInstructionModal, setShowEditInstructionModal, instructionsHaveCategory, instructionToProcess, onSetInstructionDetail } = props;
    const [instructionCategory, setInstructionCategory] = useState(instructionToProcess ? instructionToProcess.category : "");
    const [instructionName, setInstructionName] = useState(instructionToProcess ? instructionToProcess.name : "");
    const [warningMsg, setWarningMsg] = useState("");

    // console.log("currentInstructionDetail : ", currentInstructionDetail);

    useEffect(() => {
        if (instructionToProcess) {
            setInstructionCategory(instructionToProcess.category);
            setInstructionName(instructionToProcess.name);
        }
    }, [instructionToProcess]);

    function onSubmitInstruction() {
        let instructionDetail = {
            tempCategoryId: instructionToProcess.tempCategoryId,
            category: instructionCategory.trim(),
            tempInstructionId: instructionToProcess.tempInstructionId,
            name: instructionName.trim()
        }
        if (isValidInstruction(instructionDetail)) {
            onSetInstructionDetail(instructionDetail, "edit-instruction");
            onClose();
        } else {
            setWarningMsg("Please add all necessary fields");
        }
    }

    function isValidInstruction(instruction) {
        return Object.values(instruction).every(x => x !== null && x !== '');
    }

    function onClose() {
        // setInstructionName("");
        setWarningMsg("");
        setShowEditInstructionModal(false);
    }

    return (
        <div>
            {showEditInstructionModal ? (
                <>
                    <div
                        className="w-full h-full bg-slate-400 bg-opacity-50 flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-1/2 my-6 mx-auto max-w-3xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex justify-center items-center p-5 border-b border-solid border-gray-300 rounded-t ">
                                    <h3 className="text-lg font=semibold">Edit Instruction</h3>
                                </div>
                                <div className="relative p-6 flex-auto">
                                    <div>
                                        {instructionsHaveCategory && <div className="flex justify-between items-center w-full mb-3">
                                            <label
                                                className="w-1/3 text-md font-medium text-gray-900">
                                                Instruction Category
                                            </label>
                                            <input
                                                value={instructionCategory}
                                                onChange={(e) => {
                                                    setWarningMsg("");
                                                    setInstructionCategory(e.target.value);
                                                }
                                                }
                                                className=" w-2/3 p-2.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
                                            />
                                        </div>}
                                        <div className="flex justify-between items-center w-full mb-3">
                                            <label
                                                className="w-1/3 text-md font-medium text-gray-900">
                                                Instruction Name
                                            </label>
                                            <textarea
                                                value={instructionName}
                                                onChange={(e) => {
                                                    setWarningMsg("");
                                                    setInstructionName(e.target.value);
                                                }
                                                }
                                                className=" w-2/3 p-2.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between p-2.5 border-t border-solid border-blueGray-200 rounded-b">
                                    <div className="text-red-600 font-medium">
                                        {warningMsg}
                                    </div>
                                    <div>
                                        <button
                                            className="text-blue-500 background-transparent font-bold uppercase px-6 py-3 rounded text-sm mr-2 mb-1 border border-blue-500"
                                            type="button"
                                            onClick={onClose}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="text-white bg-blue-500 font-bold uppercase text-sm px-6 py-3 rounded outline-none ml-2 mb-1"
                                            type="button"
                                            onClick={onSubmitInstruction}
                                        >
                                            Edit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    )
}

export { EditInstructionModal }