import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import { LoadingIndicator } from './comps/common/loading-indicator';
import { MessageModal } from './comps/common/message-modal';
import { Navbar } from './comps/common/nav-bar';
import { ProtectedRoute } from './comps/common/protected-route';
import { isLoggedIn } from './utils/verify-auth';
import { AdminPanel } from './views/admin-panel';
import { Login } from './views/auth/login';
import { Signup } from './views/auth/signup';
import { SignupConfirmation } from './views/auth/signup-confirmation';
import { SignupResult } from './views/auth/signup-result';
import { Category } from './views/category/category';
import { EditCategory } from './views/category/edit-category';
import { NewCategory } from './views/category/new-category';
import { NewRecipe } from './views/recipe/new-recipe';
import { EditRecipe } from './views/recipe/edit-recipe';
import { Recipe } from './views/recipe/recipe';
import { EditRole } from './views/role/edit-role';
import { NewRole } from './views/role/new-role';
import { Role } from './views/role/role';
import { Status } from './views/status/status';
import { NewStatus } from './views/status/new-status';
import { EditStatus } from './views/status/edit-status';

function App() {
  const msgInfo = useSelector(state => state.common.msgInfo);
  const loading = useSelector(state => state.common.loading);
  const user = useSelector(state => state.auth.user);
  let isUserLoggedIn = isLoggedIn(user);
  const redirectedRoute = "/login";

  return (
    <div>
      <Navbar />
      <Routes>
        <Route exact path="/verify-signup/:confirmationCode" element={<SignupConfirmation />} />
        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/signup-result' element={<SignupResult />} />

        <Route path="/" element={
          <ProtectedRoute isUserLoggedIn={isUserLoggedIn} redirectedRoute={redirectedRoute}>
            <AdminPanel />
          </ProtectedRoute>
        }
        />
        <Route path="/category" element={
          <ProtectedRoute isUserLoggedIn={isUserLoggedIn} redirectedRoute={redirectedRoute}>
            <Category />
          </ProtectedRoute>
        }
        />
        <Route path="/recipe" element={
          <ProtectedRoute isUserLoggedIn={isUserLoggedIn} redirectedRoute={redirectedRoute}>
            <Recipe />
          </ProtectedRoute>
        }
        />
        <Route path="/new-recipe" element={
          <ProtectedRoute isUserLoggedIn={isUserLoggedIn} redirectedRoute={redirectedRoute}>
            <NewRecipe />
          </ProtectedRoute>
        }
        />
        <Route path="/edit-recipe" element={
          <ProtectedRoute isUserLoggedIn={isUserLoggedIn} redirectedRoute={redirectedRoute}>
            <EditRecipe />
          </ProtectedRoute>
        }
        />
        <Route path="/new-category" element={
          <ProtectedRoute isUserLoggedIn={isUserLoggedIn} redirectedRoute={redirectedRoute}>
            <NewCategory />
          </ProtectedRoute>
        }
        />
        <Route path="/edit-category" element={
          <ProtectedRoute isUserLoggedIn={isUserLoggedIn} redirectedRoute={redirectedRoute}>
            <EditCategory />
          </ProtectedRoute>
        }
        />
        <Route path="/role" element={
          <ProtectedRoute isUserLoggedIn={isUserLoggedIn} redirectedRoute={redirectedRoute}>
            <Role />
          </ProtectedRoute>
        }
        />
        <Route path="/new-role" element={
          <ProtectedRoute isUserLoggedIn={isUserLoggedIn} redirectedRoute={redirectedRoute}>
            <NewRole />
          </ProtectedRoute>
        }
        />
        <Route path="/edit-role" element={
          <ProtectedRoute isUserLoggedIn={isUserLoggedIn} redirectedRoute={redirectedRoute}>
            <EditRole />
          </ProtectedRoute>
        }
        />
        <Route path="/status" element={
          <ProtectedRoute isUserLoggedIn={isUserLoggedIn} redirectedRoute={redirectedRoute}>
            <Status />
          </ProtectedRoute>
        }
        />
        <Route path="/new-status" element={
          <ProtectedRoute isUserLoggedIn={isUserLoggedIn} redirectedRoute={redirectedRoute}>
            <NewStatus />
          </ProtectedRoute>
        }
        />
        <Route path="/edit-status" element={
          <ProtectedRoute isUserLoggedIn={isUserLoggedIn} redirectedRoute={redirectedRoute}>
            <EditStatus />
          </ProtectedRoute>
        }
        />
      </Routes>

      {loading && <LoadingIndicator />}
      {msgInfo && <MessageModal />}
    </div>
  );
}

export default App;
