import React from 'react'

const SignupResult = () => {
    return (
        <div className='w-full flex justify-center align-center'>
            <div>A confirmation link has been sent to your email address. Please confirm.</div>
        </div>
    )
}

export { SignupResult };