import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isValidString } from '../../utils/validity';

import { useDispatch } from 'react-redux';
import { setMsgInfo } from '../../store/common/commonActions';
import { updateStatus } from '../../store/status/statusActions';

const EditStatus = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const dispatch = useDispatch();
    const [status, setStatus] = useState(state ? state.status : null);

    function handleUserInput(key, value) {
        let prevStatus = { ...status };
        prevStatus[key] = value;
        setStatus(prevStatus);
    }

    function onSubmit() {
        let clonedStatus = { ...status };
        if (isValidString(clonedStatus.name)) {
            dispatch(updateStatus(clonedStatus));
            navigate("/status");
        } else {
            dispatch(setMsgInfo({ type: 'warning', msg: "Please add Status Name !" }));
        }
    }

    return (
        <div className="w-full p-2.5">
            <div className="flex justify-center items-center">
                <h2 className="text-cyan-800 text-lg font-medium">Edit Content Status</h2>
            </div>
            {status &&
                <div>
                    <div className="w-full p-2.5">
                        <div className="flex justify-center items-center w-full mb-3">
                            <label
                                className="w-1/3 text-md font-medium text-gray-900">
                                Status Name
                            </label>
                            <input
                                value={status.name}
                                onChange={(e) => handleUserInput('name', e.target.value)}
                                className=" w-2/3 p-2.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
                            />
                        </div>
                    </div>
                    <div className="flex justify-center items-center">
                        <button
                            className="text-blue-500 background-transparent font-bold uppercase px-6 py-3 rounded text-sm mr-2 mb-1 border border-blue-500"
                            type="button"
                            onClick={() => navigate("/status")}
                        >
                            Cancel
                        </button>
                        <button
                            className="text-white bg-blue-500 font-bold uppercase text-sm px-6 py-3 rounded outline-none ml-2 mb-1"
                            type="button"
                            onClick={onSubmit}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            }
        </div >
    )
}

export { EditStatus };
