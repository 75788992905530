import { TrashIcon, XMarkIcon } from '@heroicons/react/24/solid';
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import _ from 'underscore';
import { uniqueId } from '../../utils/uniqueId';
import { isValidObject, isValidString } from '../../utils/validity';
import { CustomSelect } from '../../comps/common/custom-select';

import { useDispatch, useSelector } from 'react-redux';
import { createCategory } from '../../store/category/categoryActions';
import { setMsgInfo } from '../../store/common/commonActions';

const NewCategory = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const statuses = state ? state.statuses : [];
    const [status, setStatus] = useState(statuses ? statuses[0] : null);

    const [category, setCategory] = useState({
        name: "",
        subCategories: [],
        status: status._id
    })

    function handleUserInput(key, value) {
        let prevCategoryInfo = { ...category };
        prevCategoryInfo[key] = value;
        setCategory(prevCategoryInfo);
    }

    async function onImageUpload(e, sCatTempId) {
        const imageFile = e.target.files[0];
        let prevCategoryInfo = { ...category };
        const base64Image = await convertBase64(imageFile);
        var subCategory = prevCategoryInfo.subCategories.find(subCat => subCat.tempId === sCatTempId);
        subCategory['image'] = base64Image;
        setCategory(prevCategoryInfo);
        // setImgPreviewSrc(base64Image);
        e.target.value = "";
    }

    function onRemoveImage(sCatTempId) {
        let prevCategoryInfo = { ...category };
        var subCategory = prevCategoryInfo.subCategories.find(subCat => subCat.tempId === sCatTempId);
        subCategory['image'] = "";
        setCategory(prevCategoryInfo);
    }


    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            if (file) {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(file);

                fileReader.onload = () => {
                    resolve(fileReader.result);
                };

                fileReader.onerror = (error) => {
                    reject(error);
                };
            } else {
                reject('No File Found');
            }

        });
    };

    function addEmptySubCategory() {
        let prevCategoryInfo = { ...category };
        let prevSubcategories = prevCategoryInfo.subCategories;
        prevSubcategories.push({
            name: "",
            tempId: uniqueId(),
            image: ""
        })
        prevCategoryInfo["subCategories"] = prevSubcategories;
        setCategory(prevCategoryInfo);
    }

    function modifySubCategory(name, tempId, action) {
        let prevCategory = { ...category };
        let prevSubcategories = prevCategory.subCategories;
        let indexOfSubCategory = prevSubcategories.findIndex(x => x.tempId == tempId);
        let subCategory = { name: name, tempId: tempId, image: prevSubcategories[indexOfSubCategory].image };
        if (action === "add") {
            prevSubcategories.splice(indexOfSubCategory, 1, subCategory);
        } else if (action === "delete") {
            prevSubcategories.splice(indexOfSubCategory, 1);
        }

        prevCategory["subCategories"] = prevSubcategories;
        setCategory(prevCategory);
    }

    function onSubmit() {
        let prevCategory = { ...category };
        let prevSubcategories = prevCategory.subCategories;

        prevSubcategories = getValidSubCategories(prevSubcategories);

        if (isValidString(prevCategory.name) && prevSubcategories.length) {
            prevCategory.subCategories = prevSubcategories;
            dispatch(createCategory(prevCategory));
            resetData();
            navigate("/category");
        } else {
            dispatch(setMsgInfo({ type: 'warning', msg: "Please add Category and Sub Categories !" }));
        }
    }

    function getValidSubCategories(subCategories) {
        let finalSubCategories = [];
        if (subCategories.length) {
            // add if subCategoryName is valid
            subCategories.forEach(sCat => {
                // this is done to allow sub categories without having their respected images
                const sCatWithoutImage = _.clone(sCat);
                delete sCatWithoutImage['image'];
                if (isValidObject(sCatWithoutImage)) {
                    const sCatClone = _.clone(sCat);
                    delete sCatClone['tempId'];
                    finalSubCategories.push(sCatClone);
                }
            })
            // remove duplicates
            finalSubCategories = finalSubCategories.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.name === value.name
                ))
            )
        }
        // console.log("finalSubCategories : ", finalSubCategories);
        return finalSubCategories;
    }

    function resetData() {
        setCategory({
            name: "",
            subCategories: []
        });
    }

    function handleStatusChange(status) {
        setStatus(status);
        handleUserInput('status', status._id);
    }

    return (
        <div className="w-full p-2.5">
            <div className="flex justify-center items-center">
                <h2 className="text-cyan-800 text-lg font-medium">Create New Category</h2>
            </div>
            <div className="w-full p-2.5">
                <div className="flex justify-center items-center w-full mb-3">
                    <label
                        className="w-1/3 text-md font-medium text-gray-900">
                        Category Status
                    </label>
                    <div className="w-2/3">
                        <CustomSelect accessor="name" selected={status} options={statuses} handleSelect={handleStatusChange} />
                    </div>
                </div>
                <div className="flex justify-center items-center w-full mb-3">
                    <label
                        className="w-1/3 text-md font-medium text-gray-900">
                        Category Name
                    </label>
                    <input
                        value={category.name}
                        onChange={(e) => handleUserInput('name', e.target.value)}
                        className=" w-2/3 p-2.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
                    />
                </div>
                <button
                    onClick={() => addEmptySubCategory()}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mb-3 rounded">
                    + Sub Category
                </button>
                <div>
                    {category.subCategories.length > 0 && category.subCategories.map((sCat, index) => {
                        return (
                            <div
                                key={index}
                                className="w-full mb-3 border-1 bg-slate-100 px-3 py-3"
                            >
                                <div className="flex justify-end items-center w-full mb-2">
                                    <TrashIcon
                                        onClick={() => modifySubCategory("", sCat.tempId, "delete")}
                                        className="h-6 w-6 text-red-500 cursor-pointer ml-4"
                                    />
                                </div>
                                <div className="flex justify-center items-center w-full mb-3">
                                    <label
                                        className="w-1/3 text-md font-medium text-gray-900">
                                        Sub Category Name
                                    </label>
                                    <input
                                        value={sCat.name}
                                        onChange={(e) => modifySubCategory(e.target.value, sCat.tempId, "add")}
                                        className=" w-2/3 p-2.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
                                    />
                                </div>
                                <div>
                                    <div className="flex justify-center items-center w-full mb-3">
                                        <label
                                            className="w-1/3 text-md font-medium text-gray-900">
                                            Sub Category Image
                                        </label>
                                        <input
                                            type="file"
                                            accept="image/jpg"
                                            className=" w-2/3 p-2.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
                                            onChange={(e) => onImageUpload(e, sCat.tempId)}
                                        />
                                    </div>
                                    {sCat.image.length > 1 &&
                                        <div className="relative flex justify-center items-center w-full mt-2 mb-1">
                                            <img src={sCat.image} />
                                            <XMarkIcon
                                                onClick={() => onRemoveImage(sCat.tempId)}
                                                className="absolute top-0 right-0 h-5 w-5 text-red-800 cursor-pointer ml-4"
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="flex justify-center items-center">
                <button
                    className="text-blue-500 background-transparent font-bold uppercase px-6 py-3 rounded text-sm mr-2 mb-1 border border-blue-500"
                    type="button"
                    onClick={() => navigate("/category")}
                >
                    Cancel
                </button>
                <button
                    className="text-white bg-blue-500 font-bold uppercase text-sm px-6 py-3 rounded outline-none ml-2 mb-1"
                    type="button"
                    onClick={onSubmit}
                >
                    Submit
                </button>
            </div>
        </div>
    )
}

export { NewCategory };
