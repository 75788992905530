import { FETCH_RECIPE_LIST, FETCH_RECIPE, CREATE_RECIPE, UPDATE_RECIPE, DELETE_RECIPE } from "./recipeActionTypes";

export const fetchRecipeList = (query = { skip: 0, limit: 10 }) => async (dispatch, getState) => {
    const apiEndPoint = "recipe/all";
    const user = getState().auth.user;

    await dispatch(
        {
            type: FETCH_RECIPE_LIST,
            payload: {
                apiEndPoint: apiEndPoint,
                authorizationHeader: user,
                data: { query },
                method: 'POST',
                successMsg: 'Successfully fetched recipes !',
                errorMsg: 'Failed to fetch recipes !'
            },
        }
    );
};

export const fetchRecipe = (id) => async (dispatch, getState) => {
    const apiEndPoint = "recipe/" + id;
    const user = getState().auth.user;

    dispatch({
        type: FETCH_RECIPE,
        payload: {
            apiEndPoint: apiEndPoint,
            authorizationHeader: user,
            method: 'GET',
            successMsg: 'Successfully fetched recipe !',
            errorMsg: 'Failed to fetch recipe !'
        },
    });
};

export const createRecipe = (recipe) => async (dispatch, getState) => {
    const apiEndPoint = "recipe/new";
    const user = getState().auth.user;
    recipe.summary.accessedBy = user._id;

    dispatch({
        type: CREATE_RECIPE,
        payload: {
            apiEndPoint: apiEndPoint,
            authorizationHeader: user,
            data: { content: recipe },
            method: 'POST',
            successMsg: 'Successfully created recipe !',
            errorMsg: 'Failed to create recipe !'
        },
    });
};

export const updateRecipe = (recipe) => async (dispatch, getState) => {
    const { _id: id } = recipe;
    const apiEndPoint = "recipe/" + id;
    const user = getState().auth.user;
    recipe.summary.accessedBy = user._id;

    dispatch({
        type: UPDATE_RECIPE,
        payload: {
            apiEndPoint: apiEndPoint,
            authorizationHeader: user,
            data: { content: recipe },
            method: 'PUT',
            successMsg: 'Successfully updated recipe !',
            errorMsg: 'Failed to update recipe !'
        },
    });
};

export const deleteRecipe = (id) => async (dispatch, getState) => {
    const apiEndPoint = "recipe/" + id;
    const user = getState().auth.user;

    dispatch({
        type: DELETE_RECIPE,
        payload: {
            apiEndPoint: apiEndPoint,
            authorizationHeader: user,
            method: 'DELETE',
            successMsg: 'Successfully deleted recipe !',
            errorMsg: 'Failed to delete recipe !'
        },
    });
};