import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { logout } from '../store/auth/authActions';
import { fetchCategoryList } from '../store/category/categoryActions';

const AdminPanel = () => {
    const navigate = useNavigate();

    const categoryList = useSelector(state => state.category.list);

    // console.log("from redux, loading : ", loading);
    // console.log("from redux, errorInfo : ", errorInfo ? JSON.parse(JSON.stringify(errorInfo)) : null);
    // console.log("from redux, categoryList : ", categoryList);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCategoryList());
    }, []);

    function goToPage(route) {
        navigate(route);
    }

    function signOut() {
        dispatch(logout());
    }

    return (
        <div>
            <button
                onClick={() => signOut()}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                LOGOUT
            </button>
            <div className="flex flex-col justify-center items-center w-full border-2 border-black">
                <h2 className="text-cyan-800 text-lg font-medium">AdminPanel</h2>
                <button
                    onClick={() => goToPage("/category")}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    CATEGORY
                </button>
                <button
                    onClick={() => goToPage("/recipe")}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    RECIPE
                </button>
                <button
                    onClick={() => goToPage("/role")}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    ROLE
                </button>
                <button
                    onClick={() => goToPage("/status")}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    STATUS
                </button>
                <h4>Category count : {categoryList ? categoryList.length : 0}</h4>

            </div>
        </div>
    )
}

export { AdminPanel };
