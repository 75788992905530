import { FETCH_STATUS_LIST, FETCH_STATUS, CREATE_STATUS, UPDATE_STATUS, DELETE_STATUS } from "./statusActionTypes";

export const fetchStatusList = (query = { skip: 0, limit: 10 }) => async (dispatch, getState) => {
    const apiEndPoint = "status/all";
    const user = getState().auth.user;

    await dispatch(
        {
            type: FETCH_STATUS_LIST,
            payload: {
                apiEndPoint: apiEndPoint,
                authorizationHeader: user,
                data: { query },
                method: 'POST',
                successMsg: 'Successfully fetched statuses !',
                errorMsg: 'Failed to fetch statuses !'
            },
        }
    );
};

export const fetchStatus = (id) => async (dispatch, getState) => {
    const apiEndPoint = "status/" + id;
    const user = getState().auth.user;

    dispatch({
        type: FETCH_STATUS,
        payload: {
            apiEndPoint: apiEndPoint,
            authorizationHeader: user,
            method: 'GET',
            successMsg: 'Successfully fetched status !',
            errorMsg: 'Failed to fetch status !'
        },
    });
};

export const createStatus = (status) => async (dispatch, getState) => {
    const apiEndPoint = "status/new";
    const user = getState().auth.user;

    dispatch({
        type: CREATE_STATUS,
        payload: {
            apiEndPoint: apiEndPoint,
            authorizationHeader: user,
            data: { content: status },
            method: 'POST',
            successMsg: 'Successfully created status !',
            errorMsg: 'Failed to create status !'
        },
    });
};

export const updateStatus = (status) => async (dispatch, getState) => {
    const { _id: id } = status;
    const apiEndPoint = "status/" + id;
    const user = getState().auth.user;

    dispatch({
        type: UPDATE_STATUS,
        payload: {
            apiEndPoint: apiEndPoint,
            authorizationHeader: user,
            data: { content: status },
            method: 'PUT',
            successMsg: 'Successfully updated status !',
            errorMsg: 'Failed to update status !'
        },
    });
};

export const deleteStatus = (id) => async (dispatch, getState) => {
    const apiEndPoint = "status/" + id;
    const user = getState().auth.user;

    dispatch({
        type: DELETE_STATUS,
        payload: {
            apiEndPoint: apiEndPoint,
            authorizationHeader: user,
            method: 'DELETE',
            successMsg: 'Successfully deleted status !',
            errorMsg: 'Failed to delete status !'
        },
    });
};