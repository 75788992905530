import produce from "immer";
import { FETCH_STATUS_LIST, FETCH_STATUS, CREATE_STATUS, UPDATE_STATUS, DELETE_STATUS } from "./statusActionTypes";
import { initialState } from "./statusState";


const statusReducers = (state = initialState, action) => {
    const { type, payload } = action;
    return produce(state, draft => {
        switch (type) {
            case FETCH_STATUS_LIST:
                draft.list = payload ? payload.content : null;
                break;
            case FETCH_STATUS:
                draft.status = payload ? payload.content : null;
                break;
            case CREATE_STATUS:
                draft.status = payload ? payload.content : null;
                break;
            case UPDATE_STATUS:
                draft.status = payload ? payload.content : null;
                break;
            case DELETE_STATUS:
                draft.status = payload ? payload.content : null;
                break;
            default:

        }
    });
}

export default statusReducers;