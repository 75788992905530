import { FETCH_CATEGORY_LIST, FETCH_CATEGORY, CREATE_CATEGORY, UPDATE_CATEGORY, DELETE_CATEGORY } from "./categoryActionTypes";

export const fetchCategoryList = (query = { skip: 0, limit: 10 }) => async (dispatch, getState) => {
    const apiEndPoint = "category/all";
    const user = getState().auth.user;

    await dispatch(
        {
            type: FETCH_CATEGORY_LIST,
            payload: {
                apiEndPoint: apiEndPoint,
                authorizationHeader: user,
                data: { query },
                method: 'POST',
                successMsg: 'Successfully fetched categories !',
                errorMsg: 'Failed to fetch categories !'
            },
        }
    );
};

export const fetchCategory = (id) => async (dispatch, getState) => {
    const apiEndPoint = "category/" + id;
    const user = getState().auth.user;

    dispatch({
        type: FETCH_CATEGORY,
        payload: {
            apiEndPoint: apiEndPoint,
            authorizationHeader: user,
            method: 'GET',
            successMsg: 'Successfully fetched category !',
            errorMsg: 'Failed to fetch category !'
        },
    });
};

export const createCategory = (category) => async (dispatch, getState) => {
    const apiEndPoint = "category/new";
    const user = getState().auth.user;
    category.accessedBy = user._id;

    dispatch({
        type: CREATE_CATEGORY,
        payload: {
            apiEndPoint: apiEndPoint,
            authorizationHeader: user,
            data: { content: category },
            method: 'POST',
            successMsg: 'Successfully created category !',
            errorMsg: 'Failed to create category !'
        },
    });
};

export const updateCategory = (category) => async (dispatch, getState) => {
    const { _id: id } = category;
    const apiEndPoint = "category/" + id;
    const user = getState().auth.user;
    category.accessedBy = user._id;

    dispatch({
        type: UPDATE_CATEGORY,
        payload: {
            apiEndPoint: apiEndPoint,
            authorizationHeader: user,
            data: { content: category },
            method: 'PUT',
            successMsg: 'Successfully updated category !',
            errorMsg: 'Failed to update category !'
        },
    });
};

export const deleteCategory = (id) => async (dispatch, getState) => {
    const apiEndPoint = "category/" + id;
    const user = getState().auth.user;

    dispatch({
        type: DELETE_CATEGORY,
        payload: {
            apiEndPoint: apiEndPoint,
            authorizationHeader: user,
            method: 'DELETE',
            successMsg: 'Successfully deleted category !',
            errorMsg: 'Failed to delete category !'
        },
    });
};