import { SIGN_UP, VERIFY_SIGN_UP, LOG_IN, LOG_OUT, RESET_AUTH_STATE, SET_USER } from "./authActionTypes";

export const signup = (data) => async (dispatch) => {
    const apiEndPoint = "auth/signup";
    console.log("signup in store : ", data);

    await dispatch(
        {
            type: SIGN_UP,
            payload: {
                apiEndPoint: apiEndPoint,
                data: data,
                method: 'POST',
                successMsg: 'Successfully Signed Up !',
                errorMsg: 'Failed to Sign Up !'
            },
        }
    );
};

export const verifySignup = (confirmationCode) => async (dispatch) => {
    const apiEndPoint = "auth/verify-signup/" + confirmationCode;

    await dispatch(
        {
            type: VERIFY_SIGN_UP,
            payload: {
                apiEndPoint: apiEndPoint,
                method: 'GET',
                successMsg: 'Sign Up Verification Successful !',
                errorMsg: 'Sign Up Verification Failed !'
            },
        }
    );
};

export const login = (data) => (dispatch) => {
    const apiEndPoint = "auth/login";
    dispatch(
        {
            type: LOG_IN,
            payload: {
                apiEndPoint: apiEndPoint,
                data: data,
                method: 'POST',
                successMsg: 'Successfully Logged In !',
                errorMsg: 'Failed to Log In !'
            },
        }
    );
};

export const logout = (user = null) => async (dispatch) => {
    dispatch({
        type: LOG_OUT,
        payload: {
            user: user
        }
    });
};

export const resetAuthState = (isLoggedIn = false, token = null, user = null) => async (dispatch) => {
    await dispatch(
        {
            type: RESET_AUTH_STATE,
            payload: {
                isLoggedIn: isLoggedIn,
                token: token,
                user: user
            },
        }
    );
};

export const setUser = (user = null) => (dispatch) => {
    dispatch({
        type: SET_USER,
        payload: {
            user: user
        }
    });
};