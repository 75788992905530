import { SET_LOADING_STATUS, SET_MSG_INFO, SET_ERROR_INFO, RESET_COMMON_STATE } from "./commonActionTypes";

export const setLoadingStatus = (loadingStatus = false) => async (dispatch) => {
    dispatch({
        type: SET_LOADING_STATUS,
        payload: {
            loadingStatus: loadingStatus
        }
    });
};

export const setMsgInfo = (msgInfo = null) => async (dispatch) => {
    dispatch({
        type: SET_MSG_INFO,
        payload: {
            msgInfo: msgInfo
        }
    });
};

export const setErrorInfo = (errorInfo = null) => async (dispatch) => {
    dispatch({
        type: SET_ERROR_INFO,
        payload: {
            errorInfo: errorInfo
        }
    });
};

export const resetCommonState = (loadingStatus = false, msgInfo = null, errorInfo = null) => async (dispatch) => {
    dispatch({
        type: RESET_COMMON_STATE,
        payload: {
            loadingStatus: loadingStatus,
            msgInfo: msgInfo,
            errorInfo: errorInfo
        },
    });
};