class ApiService {
    constructor(apiEndPoint) {
        this.hostUrl = this.getApiHostUrl();
        this.apiConfig = {
            method: "",
            headers: { 'Content-Type': 'application/json' },
        };
        this.apiUrl = this.hostUrl + "/api/" + apiEndPoint;
    }

    async getData() {
        this.apiConfig.method = "GET";
        return fetch(this.apiUrl, this.apiConfig)
            .then(this.processResponseStatus)
            .then(response => response.json())
            .catch(err => { throw err });
    }

    async postData(body) {
        this.apiConfig.method = "POST";
        this.apiConfig.body = body;
        return fetch(this.apiUrl, this.apiConfig)
            .then(this.processResponseStatus)
            .then(response => response.json())
            .catch(err => { throw err });
    }

    async putData(body) {
        this.apiConfig.method = "PUT";
        this.apiConfig.body = body;
        return fetch(this.apiUrl, this.apiConfig)
            .then(this.processResponseStatus)
            .then(response => response.json())
            .catch(err => { throw err });
    }

    async deleteData() {
        this.apiConfig.method = "DELETE";
        return fetch(this.apiUrl, this.apiConfig)
            .then(this.processResponseStatus)
            .then(response => response.json())
            .catch(err => { throw err });
    }

    async makeApiCall(method, body, authorizationHeader = null) {
        this.apiConfig.method = method;
        if (method !== 'GET') {
            this.apiConfig.body = body;
        }
        if (authorizationHeader) {
            this.apiConfig.headers.Authorization = 'Basic ' + btoa(JSON.stringify(authorizationHeader));
        }
        return fetch(this.apiUrl, this.apiConfig)
            .then(this.processResponseStatus)
            .then(response => response.json())
            .catch(err => { throw err });
    }

    async processResponseStatus(res) {
        if (!res.ok) {
            const resJson = await res.json();
            let error = new Error();
            const errorDetails = {
                statusText: res.statusText,
                type: res.type,
                message: resJson.message,
                name: resJson.name,
                statusCode: resJson.statusCode
            }
            Object.assign(error, errorDetails);
            throw error;
        }
        return res;
    }

    getApiHostUrl() {
        return process.env.NODE_ENV === "development" ? process.env.REACT_APP_DEV_API_HOST : process.env.REACT_APP_PRODUCTION_API_HOST;
    }
}

export default ApiService;