import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { generateRandomKey } from '../../../helpers/helper';
import { setMsgInfo } from '../../../store/common/commonActions';

import { AddInstructionModal } from './add-instruction-modal';
import { DeleteInstructionModal } from './delete-instruction-modal';
import { EditInstructionModal } from './edit-instruction-modal';

const Instructions = ({ instructionDetail, setInstructionDetail }) => {
    const dispatch = useDispatch();
    const [showAddInstructionModal, setShowAddInstructionModal] = useState(false);
    const [showEditInstructionModal, setShowEditInstructionModal] = useState(false);
    const [showDeleteInstructionModal, setShowDeleteInstructionModal] = useState(false);

    const [instructionToProcess, setInstructionToProcess] = useState(null);

    function onEditInstruction(category, tempCategoryId, name, tempInstructionId) {
        setInstructionToProcess({
            tempCategoryId: tempCategoryId,
            category: category,
            tempInstructionId: tempInstructionId,
            name: name
        })
        setShowEditInstructionModal(true);
    }

    function onDeleteInstruction(category, tempCategoryId, name, tempInstructionId) {
        setInstructionToProcess({
            tempCategoryId: tempCategoryId,
            category: category,
            tempInstructionId: tempInstructionId,
            name: name
        })
        setShowDeleteInstructionModal(true);
    }

    function onSetInstructionDetail(detail, ref) {
        if (ref === "add-instruction" || ref === "edit-instruction" || ref === "delete-instruction") {
            // console.log("from modal : ", val);
            detail = getInstructionDetails(detail, ref)
        }
        let newInstructionDetail = { ...instructionDetail, ...detail };
        // console.log("newInstructionDetail : ", newInstructionDetail);
        setInstructionDetail(newInstructionDetail);
    }


    function getDataIndex(arr, key, value) {
        if (arr.length) {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i][key] === value) {
                    return i;
                }
            }
        }
        return -1;
    }

    function resetInstructionToProcess() {
        setInstructionToProcess({
            tempCategoryId: "",
            category: "",
            tempInstructionId: "",
            name: ""
        })
    }

    function getInstructionDetails(details, ref) {
        let prevInstructionDetail = instructionDetail;
        let instructionList = prevInstructionDetail.instructionList;
        let categoryPositionInInstructionList = -1;
        let instructionPositionInInstructionArr = -1;

        if (ref === "add-instruction") {
            categoryPositionInInstructionList = getDataIndex(instructionList, 'category', details.category);
            if (categoryPositionInInstructionList >= 0) {
                instructionPositionInInstructionArr = getDataIndex(instructionList[categoryPositionInInstructionList].instructions, 'name', details.name);
            }

            if (categoryPositionInInstructionList < 0 && instructionPositionInInstructionArr < 0) {
                let instructionListItem = { category: "", tempCategoryId: "", instructions: [] };
                instructionListItem.category = details.category;
                instructionListItem.tempCategoryId = generateRandomKey('cat');
                instructionListItem.instructions.push(
                    {
                        name: details.name,
                        tempInstructionId: generateRandomKey('ins')
                    }
                )
                instructionList.push(instructionListItem);
            } else if (categoryPositionInInstructionList >= 0 && instructionPositionInInstructionArr < 0) {
                instructionList[categoryPositionInInstructionList].instructions.push({ name: details.name, tempInstructionId: generateRandomKey('ins') });
            } else if (categoryPositionInInstructionList >= 0 && instructionPositionInInstructionArr >= 0) {
                dispatch(setMsgInfo({ type: 'warning', msg: `Instruction exists in category "${details.category}"` }));
            }
        } else {
            categoryPositionInInstructionList = getDataIndex(instructionList, 'tempCategoryId', details.tempCategoryId);
            if (categoryPositionInInstructionList >= 0) {
                instructionPositionInInstructionArr = getDataIndex(instructionList[categoryPositionInInstructionList].instructions, 'tempInstructionId', details.tempInstructionId);
            }

            if (categoryPositionInInstructionList >= 0 && instructionPositionInInstructionArr >= 0) {
                if (ref === "edit-instruction") {
                    instructionList[categoryPositionInInstructionList].category = details.category;
                    instructionList[categoryPositionInInstructionList].instructions.splice(instructionPositionInInstructionArr, 1, { name: details.name, tempInstructionId: details.tempInstructionId });
                    resetInstructionToProcess();
                } else if (ref === "delete-instruction") {
                    if (instructionList[categoryPositionInInstructionList].instructions.length === 1) {
                        instructionList.splice(categoryPositionInInstructionList, 1);
                    } else {
                        instructionList[categoryPositionInInstructionList].instructions.splice(instructionPositionInInstructionArr, 1);
                    }
                    resetInstructionToProcess();
                }
            }
        }

        // console.log("prevInstructionDetail : ", prevInstructionDetail);

        return prevInstructionDetail;
    }

    return (
        <div className="w-full p-2.5">
            <div className="w-full">
                <div className="w-full p-2.5 bg-sky-100">
                    <h2 className="text-blue-800 text-lg font-medium">Instructions</h2>
                    <div className="w-full p-2.5 flex justify-between items-center mb-3">
                        <div className="flex justify-start items-center">
                            <label
                                className="mr-2 text-md font-medium text-gray-900">
                                Instructions Have Breakdown
                            </label>
                            <input type="checkbox"
                                checked={instructionDetail.instructionsHaveCategory}
                                onChange={(e) => onSetInstructionDetail({ instructionsHaveCategory: e.target.checked }, 'instructions-have-category')}
                                className="accent-green-600"
                            />
                        </div>
                        <button
                            onClick={() => setShowAddInstructionModal(true)}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            + Instruction
                        </button>
                    </div>
                    {instructionDetail.instructionList.length > 0 && <div className="w-full p-2.5 flex justify-between items-center mb-3">
                        <table className="w-full border-2 border-slate-50">
                            <thead>
                                <tr>
                                    <th className="border-2 border-slate-800 p-2.5 w-1/4">Instruction Category</th>
                                    <th className="border-2 border-slate-800 p-2.5 w-1/4">Instruction Name</th>
                                    <th colSpan={2} className="border-2 border-slate-800 p-2.5 w-2/4">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {instructionDetail.instructionList.map((listItem, i) => {
                                    return (
                                        <tr
                                            key={listItem.tempCategoryId}
                                        >
                                            <td

                                                className="border-2 border-slate-800 p-2.5 w-1/5"
                                            >
                                                <div className="w-full flex justify-center items-center">
                                                    {listItem.category}
                                                </div>
                                            </td>
                                            <td
                                                colSpan={4}
                                                className="border-2 border-slate-800 w-4/5">
                                                <table className="w-full">
                                                    <tbody className="w-full">
                                                        {listItem.instructions.length > 0 && listItem.instructions.map((instruction, j) => {
                                                            return (
                                                                <tr
                                                                    key={instruction.tempInstructionId}
                                                                    className="w-full border-2 border-slate-800"
                                                                >
                                                                    <td
                                                                        className="w-1/4 p-2.5 border-2 border-r-slate-800"
                                                                    >
                                                                        <div className="w-full flex justify-center items-center">
                                                                            {instruction.name}
                                                                        </div>
                                                                    </td>
                                                                    <td className="w-1/4 p-2.5 border-2 border-r-slate-800" >
                                                                        <div className="w-full flex justify-center items-center">
                                                                            <PencilIcon
                                                                                onClick={() => onEditInstruction(listItem.category, listItem.tempCategoryId, instruction.name, instruction.tempInstructionId)}
                                                                                className="h-6 w-6 text-blue-500 cursor-pointer"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td className="w-1/4 p-2.5 border-2 border-l-slate-800" >
                                                                        <div className="w-full flex justify-center items-center">
                                                                            <TrashIcon
                                                                                onClick={() => onDeleteInstruction(listItem.category, listItem.tempCategoryId, instruction.name, instruction.tempInstructionId)}
                                                                                className="h-6 w-6 text-red-500 cursor-pointer"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>}
                </div>
                <AddInstructionModal
                    showAddInstructionModal={showAddInstructionModal}
                    setShowAddInstructionModal={setShowAddInstructionModal}
                    instructionsHaveCategory={instructionDetail.instructionsHaveCategory}
                    onSetInstructionDetail={onSetInstructionDetail}
                    generateRandomKey={generateRandomKey}
                />
                <EditInstructionModal
                    showEditInstructionModal={showEditInstructionModal}
                    setShowEditInstructionModal={setShowEditInstructionModal}
                    instructionsHaveCategory={instructionDetail.instructionsHaveCategory}
                    instructionToProcess={instructionToProcess}
                    onSetInstructionDetail={onSetInstructionDetail}
                />
                <DeleteInstructionModal
                    showDeleteInstructionModal={showDeleteInstructionModal}
                    setShowDeleteInstructionModal={setShowDeleteInstructionModal}
                    instructionToProcess={instructionToProcess}
                    onSetInstructionDetail={onSetInstructionDetail}
                />
            </div>
        </div>
    )
}

export { Instructions };
