import React, { useState } from 'react';
import { CustomSelect } from '../../common/custom-select';
import { XMarkIcon } from '@heroicons/react/24/solid';

const Summary = (props) => {
    const { summary, setSummary, categories, statuses } = props;
    const [category, setCategory] = useState(getCategory(summary.categoryId, categories));
    const [subCategory, setSubCategory] = useState(getSubCategory(summary.categoryId, summary.subCategoryId, categories));
    const [status, setStatus] = useState(summary.status ? getStatus(summary.status, statuses) : null);
    // console.log("summary : ", summary);

    function getStatus(statusId, statuses) {
        return statuses.find(status => status._id === statusId);
    }

    async function onImageUpload(imageFile) {
        let prevSummaryInfo = { ...summary };
        const base64Image = await convertBase64(imageFile);
        prevSummaryInfo['image'] = base64Image;
        setSummary(prevSummaryInfo);
    }

    function onRemoveImage(summary) {
        let prevSummaryInfo = { ...summary };
        prevSummaryInfo['image'] = "";
        prevSummaryInfo['imageUrl'] = "";
        setSummary(prevSummaryInfo);
    }

    function getImageSrc(summary) {
        if (!summary.image.length && summary.imageUrl.length) {
            const hostUrl = process.env.NODE_ENV === "development" ? process.env.REACT_APP_DEV_API_HOST : process.env.REACT_APP_PRODUCTION_API_HOST;
            return `${hostUrl}/${summary.imageUrl}`;
        } else if ((summary.image.length && summary.imageUrl.length) || (summary.image.length && !summary.imageUrl.length)) {
            return `${summary.image}`;
        }

        return '';
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    function handleUserInput(modifiedSummary) {
        let newSummary = { ...summary, ...modifiedSummary };
        setSummary(newSummary);
    }

    function handleCategoryChange(category) {
        setCategory(category);
        setSubCategory(category.subCategories[0]);
        const newSummary = {
            'categoryId': category._id,
            'subCategoryId': category.subCategories[0]._id
        }

        handleUserInput(newSummary);
    }

    function handleSubCategoryChange(subCategory) {
        setSubCategory(subCategory);
        const newSummary = {
            'subCategoryId': subCategory._id
        }
        handleUserInput(newSummary);
    }

    function getCategory(categoryId, categories) {
        return categories.find(category => category._id === categoryId);
    }

    function getSubCategory(categoryId, subCategoryId, categories) {
        const category = categories.find(category => category._id === categoryId);
        return category.subCategories.find(subCategory => subCategory._id === subCategoryId);
    }

    function handleStatusChange(status) {
        setStatus(status);
        const newSummary = {
            'status': status._id
        };
        handleUserInput(newSummary);
    }

    return (
        <div className="w-full p-2.5">
            <div className="w-full">
                <div className="w-full p-2.5 bg-sky-100 mb-4">
                    <h2 className="text-blue-800 text-lg font-medium">Summary</h2>
                    <div className="w-full p-2.5">
                        <div className="flex justify-center items-center w-full mb-3">
                            <label
                                className="w-1/3 text-md font-medium text-gray-900">
                                Recipe Status
                            </label>
                            <div className="w-2/3">
                                <CustomSelect accessor="name" selected={status} options={statuses} handleSelect={handleStatusChange} />
                            </div>
                        </div>
                        <div className="flex justify-center items-center w-full mb-3">
                            <label
                                className="w-1/3 text-md font-medium text-gray-900">
                                Category Name
                            </label>
                            <div className="w-2/3">
                                <CustomSelect accessor="name" selected={category} options={categories} handleSelect={handleCategoryChange} />
                            </div>
                        </div>

                        <div className="flex justify-center items-center w-full mb-3">
                            <label
                                className="w-1/3 text-md font-medium text-gray-900">
                                Sub Category Name
                            </label>
                            <div className="w-2/3">
                                <CustomSelect accessor="name" selected={subCategory} options={category.subCategories} handleSelect={handleSubCategoryChange} />
                            </div>
                        </div>

                        <div className="flex justify-center items-center w-full mb-3">
                            <label
                                className="w-1/3 text-md font-medium text-gray-900">
                                Recipe Name
                            </label>
                            <input
                                value={summary.name}
                                onChange={(e) => handleUserInput({ 'name': e.target.value })}
                                className=" w-2/3 p-2.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
                            />
                        </div>

                        <div className="flex justify-center items-center w-full mb-3">
                            <label
                                className="w-1/3 text-md font-medium text-gray-900">
                                Total Cooking Time
                            </label>
                            <input
                                value={summary.totalCookingTime}
                                onChange={(e) => handleUserInput({ 'totalCookingTime': e.target.value })}
                                className=" w-2/3 p-2.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
                            />
                        </div>

                        <div className="flex justify-center items-center w-full mb-3">
                            <label
                                className="w-1/3 text-md font-medium text-gray-900">
                                Total Servings
                            </label>
                            <input
                                value={summary.totalServings}
                                onChange={(e) => handleUserInput({ 'totalServings': e.target.value })}
                                className=" w-2/3 p-2.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
                            />
                        </div>

                        <div className="flex justify-center items-center w-full mb-3">
                            <label
                                className="w-1/3 text-md font-medium text-gray-900">
                                Recipe Image
                            </label>
                            <input
                                type="file"
                                accept="image/jpg"
                                className=" w-2/3 p-2.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
                                onChange={(e) => onImageUpload(e.target.files[0])}
                            />
                        </div>
                        {(summary.image.length > 1 || summary.imageUrl.length > 1) &&
                            <div className="relative flex justify-center items-center w-full mt-2 mb-3">
                                <img src={getImageSrc(summary)} />
                                <XMarkIcon
                                    onClick={() => onRemoveImage(summary)}
                                    className="absolute top-0 right-0 h-5 w-5 text-red-800 cursor-pointer ml-4"
                                />
                            </div>
                        }
                        <div className="flex justify-center items-center w-full mb-3">
                            <label
                                className="w-1/3 text-md font-medium text-gray-900">
                                Recipe Source
                            </label>
                            <input
                                value={summary.recipeSource}
                                onChange={(e) => handleUserInput({ 'recipeSource': e.target.value })}
                                className=" w-2/3 p-2.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { Summary };
