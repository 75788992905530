import React, { useEffect, useState } from 'react';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { DeleteRoleModal } from '../../comps/role/delete-role-modal';

import { useDispatch, useSelector } from 'react-redux';
import { deleteRole, fetchRoleList } from '../../store/role/roleActions';

const Role = () => {
    const navigate = useNavigate();
    const roles = useSelector(state => state.role.list);
    const loading = useSelector(state => state.common.loading);
    const [roleToProcess, setRoleToProcess] = useState(null);
    const [showDeleteRoleModal, setShowDeleteRoleModal] = useState(false);
    const dispatch = useDispatch();

    const [apiQuery, setApiQuery] = useState({
        skip: 0,
        limit: 10
    })

    useEffect(() => {
        dispatch(fetchRoleList());
    }, []);

    function goToPage(route) {
        navigate(route);
    }

    function onEditRole(role) {
        navigate("/edit-role", {
            state: { role: role }
        })
    }

    function onDeleteRole(role) {
        setRoleToProcess(role);
        setShowDeleteRoleModal(true);
    }

    async function processDelete(id) {
        dispatch(deleteRole(id));
        dispatch(fetchRoleList(apiQuery));
        setShowDeleteRoleModal(false);
    }

    return (
        <div className="flex flex-col justify-center items-center w-full">
            <div>
                <h2 className="text-cyan-800 text-lg font-medium">Role Page</h2>
                <button
                    onClick={() => goToPage("/new-role")}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    + ROLE
                </button>
            </div>
            <div className="flex flex-col justify-center items-start w-full pl-4 pr-4">
                {!loading && roles && roles.length > 0 && roles.map((role) => {
                    return (
                        <div
                            key={role._id}
                            className="flex justify-between items-start w-full p-4 bg-slate-100 rounded-lg mb-3"
                        >
                            <div className="w-2/3">
                                <div className="w-full text-lg font-bold mb-2">
                                    {role.name}
                                </div>
                            </div>
                            <div className="flex justify-center items-center w-auto">
                                <PencilIcon
                                    onClick={() => onEditRole(role)}
                                    className="h-5 w-5 text-blue-500 cursor-pointer mr-3"
                                />
                                <TrashIcon
                                    onClick={() => onDeleteRole(role)}
                                    className="h-5 w-5 text-red-500 cursor-pointer"
                                />
                            </div>
                        </div>
                    )
                })}
                {!loading && roles && roles.length < 1 &&
                    <div className="flex flex-col justify-center items-center w-full mt-3">
                        <h2>Role List is Empty!</h2>
                        <h3>Create New Role.</h3>
                    </div>
                }
            </div>
            <DeleteRoleModal
                showDeleteRoleModal={showDeleteRoleModal}
                setShowDeleteRoleModal={setShowDeleteRoleModal}
                role={roleToProcess}
                processDelete={processDelete}
            />

        </div>
    )
}

export { Role };
