import React from 'react';
import { NavLink } from 'react-router-dom';

const Navbar = () => {
    return (
        <div className="flex justify-center itmes-center">
            <NavLink to={'/'} className="text-blue-600">
                Home
            </NavLink>
        </div>
    )
}

export { Navbar };