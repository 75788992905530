import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeleteRecipeModal } from '../../comps/recipe/delete-recipe-modal';

import { useDispatch, useSelector } from 'react-redux';
import { deleteRecipe, fetchRecipeList } from '../../store/recipe/recipeActions';
import { fetchStatusList } from '../../store/status/statusActions';

const Recipe = () => {
    const navigate = useNavigate();
    let categories = useSelector(state => state.category.list);
    const statuses = useSelector(state => state.status.list);
    categories = categories.filter(cat => isPublished(cat.status, statuses));
    const recipes = useSelector(state => state.recipe.list);
    const [recipeToProcess, setRecipeToProcess] = useState(null);
    const [showDeleteRecipeModal, setShowDeleteRecipeModal] = useState(false);

    const loading = useSelector(state => state.common.loading);
    const dispatch = useDispatch();

    const [apiQuery, setApiQuery] = useState({
        skip: 0,
        limit: 1000
    })

    useEffect(() => {
        dispatch(fetchRecipeList(apiQuery));
        dispatch(fetchStatusList());
    }, []);

    function isPublished(statusId, statuses) {
        if (statuses) {
            const publishedStatus = statuses.find(status => status.name === "Published");
            return publishedStatus._id == statusId;
        }
        return false;
    }

    function getStatus(statusId) {
        if (statuses && statuses.length) {
            const status = statuses.find(status => status._id === statusId);
            if (status) {
                return status.name;
            }
        }

        return "";
    }

    function goToPage(route) {
        navigate(route, {
            state: { categories: categories, statuses: statuses }
        })
    }

    function onEditRecipe(recipe) {
        navigate("/edit-recipe", {
            state: { categories: categories, recipe: recipe, statuses: statuses }
        })
    }

    function onDeleteRecipe(recipe) {
        setRecipeToProcess(recipe);
        setShowDeleteRecipeModal(true);
    }

    async function processDelete(id) {
        dispatch(deleteRecipe(id));
        dispatch(fetchRecipeList(apiQuery));
        setShowDeleteRecipeModal(false);
    }

    function getReverseArr(arr) {
        let tempArr = [...arr];
        return tempArr.reverse();
    }

    return (
        <div className="flex flex-col justify-center items-center w-full">
            <div>
                <h2 className="text-cyan-800 text-lg font-medium">Recipe Page</h2>
                {!loading && recipes && recipes.length > 0 &&
                    <h3 className="text-cyan-600 text-md font-medium">{recipes.length} / 278</h3>
                }
                <button
                    onClick={() => goToPage("/new-recipe")}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    + RECIPE
                </button>
            </div>
            <div className="flex flex-col justify-center items-start w-full pl-4 pr-4">
                {!loading && recipes && recipes.length > 0 && getReverseArr(recipes).map((recipe) => {
                    return (
                        <div
                            key={recipe._id}
                            className="flex justify-between items-start w-full p-4 bg-slate-100 rounded-lg mb-3"
                        >
                            <div className="w-2/3">
                                <div className="text-lg font-bold mb-2">
                                    {recipe.summary.name}
                                </div>
                                <div className="flex flex-wrap justify-start items-center w-auto ml-4 mr-4">
                                    <div className="pl-4 pr-4 pt-1 pb-1 ml-4 mr-4 bg-slate-300 rounded-lg">
                                        {recipe.summary.totalCookingTime}
                                    </div>
                                    <div className="pl-4 pr-4 pt-1 pb-1 ml-4 mr-4 bg-slate-300 rounded-lg">
                                        {recipe.summary.totalServings}
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-center items-center w-auto">
                                {getStatus(recipe.summary.status).length && getStatus(recipe.summary.status) == "Published" &&
                                    <span className="text-green-600 text-md font-medium">Published</span>
                                }
                                {getStatus(recipe.summary.status).length && getStatus(recipe.summary.status) == "Draft" &&
                                    <span className="text-red-600 text-md font-medium">Draft</span>
                                }
                                <PencilIcon
                                    onClick={() => onEditRecipe(recipe)}
                                    className="h-5 w-5 text-blue-500 cursor-pointer mr-3"
                                />
                                <TrashIcon
                                    onClick={() => onDeleteRecipe(recipe)}
                                    className="h-5 w-5 text-red-500 cursor-pointer"
                                />
                            </div>
                        </div>
                    )
                })}
                {!loading && recipes && recipes.length < 1 &&
                    <div className="flex flex-col justify-center items-center w-full mt-3">
                        <h2>Recipe List is Empty!</h2>
                        <h3>Create New Recipe.</h3>
                    </div>
                }
            </div>
            <DeleteRecipeModal
                showDeleteRecipeModal={showDeleteRecipeModal}
                setShowDeleteRecipeModal={setShowDeleteRecipeModal}
                recipe={recipeToProcess}
                processDelete={processDelete}
            />

        </div>
    )
}

export { Recipe };
