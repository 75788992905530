import produce from "immer";
import { initialState } from "./commonState";
import { SET_LOADING_STATUS, SET_MSG_INFO, SET_ERROR_INFO, RESET_COMMON_STATE } from "./commonActionTypes";


const commonReducers = (state = initialState, action) => {
    const { type, payload } = action;
    return produce(state, draft => {
        switch (type) {
            case SET_LOADING_STATUS:
                draft.loading = payload.loadingStatus;
                break;
            case SET_MSG_INFO:
                draft.msgInfo = payload.msgInfo;
                break;
            case SET_ERROR_INFO:
                draft.errorInfo = payload.errorInfo;
                break;
            case RESET_COMMON_STATE:
                draft.loading = payload.loading;
                draft.msgInfo = payload.msgInfo;
                break;
            default:

        }
    });
}

export default commonReducers;