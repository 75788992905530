import React from 'react';

const DeleteInstructionModal = (props) => {
    const { showDeleteInstructionModal, setShowDeleteInstructionModal, instructionToProcess, onSetInstructionDetail } = props;

    function onDelete() {
        onSetInstructionDetail(instructionToProcess, "delete-instruction");
        setShowDeleteInstructionModal(false);
    }

    return (
        <div>
            {showDeleteInstructionModal ? (
                <>
                    <div
                        className="w-full h-full bg-slate-400 bg-opacity-50 flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-1/2 my-6 mx-auto max-w-3xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex flex-col justify-center items-center p-5 border-b border-solid border-gray-300 rounded-t ">
                                    <h3 className="text-lg font=semibold">Are you sure you want to delete below instruction: </h3>
                                    <h4 className="text-xl font=semibold">{instructionToProcess.name}</h4>
                                </div>
                                <div className="flex items-center justify-end p-2.5 border-t border-solid border-blueGray-200 rounded-b">
                                    <div>
                                        <button
                                            className="text-blue-500 background-transparent font-bold uppercase px-6 py-3 rounded text-sm mr-2 mb-1 border border-blue-500"
                                            type="button"
                                            onClick={() => setShowDeleteInstructionModal(false)}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="text-white bg-red-500 font-bold uppercase text-sm px-6 py-3 rounded outline-none ml-2 mb-1"
                                            type="button"
                                            onClick={onDelete}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    )
}

export { DeleteInstructionModal };
