import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = (props) => {
    const { isUserLoggedIn, redirectedRoute, children } = props;
    // console.log("isUserLoggedIn : ", isUserLoggedIn);
    if (!isUserLoggedIn) {
        return <Navigate to={redirectedRoute} replace />;
    }

    return children;
};

export { ProtectedRoute };
