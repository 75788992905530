import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signup } from '../../store/auth/authActions';
import { Link } from 'react-router-dom';
import { isValidObject } from '../../utils/validity';
import { useNavigate } from 'react-router-dom';

const Signup = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const msgInfo = useSelector(state => state.common.msgInfo);
    const [passWordCheckWarningMsg, setPassWordCheckWarningMsg] = useState('');
    const [warningMsg, setWarningMsg] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [credential, setCredential] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: ""
    });

    useEffect(() => {
        if (msgInfo) {
            if (msgInfo.type == 'success') {
                navigate("/signup-result")
            }
        }
    })

    function handleUserInput(key, value) {
        setWarningMsg('');
        let prevCredential = { ...credential };
        prevCredential[key] = value;
        setCredential(prevCredential);
    }

    function checkPassword(val) {
        if (val.length && credential.password.length) {
            if (val !== credential.password) {
                setPassWordCheckWarningMsg('Password does not match');
            } else {
                setPassWordCheckWarningMsg('');
            }
        }
    }

    function onSignUp() {
        if (isValidObject(credential)) {
            dispatch(signup(credential));
        } else {
            setWarningMsg('Please add all required information')
        }
    }

    return (
        <div className='w-full p-4'>
            <div className="w-full flex flex-col justify-center align-center">
                <div className="flex justify-center align-center mb-4">
                    <h1 className="text-lg font-medium">Signup Page</h1>
                </div>
                <div className="flex justify-center items-center mb-3">
                    <label
                        className="w-1/3 text-md font-medium text-gray-900">
                        First Name
                    </label>
                    <input
                        value={credential.firstName}
                        onChange={(e) => handleUserInput('firstName', e.target.value)}
                        className=" w-2/3 p-2.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
                    />
                </div>
                <div className="flex justify-center items-center mb-3">
                    <label
                        className="w-1/3 text-md font-medium text-gray-900">
                        Last Name
                    </label>
                    <input
                        value={credential.lastName}
                        onChange={(e) => handleUserInput('lastName', e.target.value)}
                        className=" w-2/3 p-2.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
                    />
                </div>
                <div className="flex justify-center items-center mb-3">
                    <label
                        className="w-1/3 text-md font-medium text-gray-900">
                        Email
                    </label>
                    <input
                        value={credential.email}
                        onChange={(e) => handleUserInput('email', e.target.value)}
                        className=" w-2/3 p-2.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
                    />
                </div>
                <div className="flex justify-center items-center mb-3">
                    <label
                        className="w-1/3 text-md font-medium text-gray-900">
                        Password
                    </label>
                    <input
                        value={credential.password}
                        onChange={(e) => handleUserInput('password', e.target.value)}
                        className=" w-2/3 p-2.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
                        type={showPassword ? 'text' : 'password'}
                    />
                </div>
                <div className="flex justify-center items-center mb-3">
                    <label
                        className="w-1/3 text-md font-medium text-gray-900">
                        Confirm Password
                    </label>
                    <input
                        onChange={(e) => checkPassword(e.target.value)}
                        onPaste={(e) => checkPassword(e.clipboardData.getData('text'))}
                        className="w-2/3 p-2.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
                        type={showPassword ? 'text' : 'password'}
                    />
                </div>

                <div className="flex mb-3">
                    <div className='w-1/3'></div>
                    <div className="w-2/3">
                        <div className="flex justify-start items-center">
                            <label
                                className="mr-2 text-md font-sm text-gray-900">
                                Show Password
                            </label>
                            <input type="checkbox"
                                checked={showPassword}
                                onChange={(e) => setShowPassword(e.target.checked)}
                                className="accent-green-600"
                            />
                        </div>
                    </div>
                </div>
                {passWordCheckWarningMsg &&
                    <div className="flex mb-3">
                        <div className='w-1/3'></div>
                        <div className="w-2/3 text-red-600 font-medium">
                            *Warning: {passWordCheckWarningMsg}
                        </div>
                    </div>
                }
                {warningMsg &&
                    <div className="flex mb-3">
                        <div className='w-1/3'></div>
                        <div className="w-2/3 text-red-600 font-medium">
                            *Warning: {warningMsg}
                        </div>
                    </div>
                }
                <div className="flex justify-center items-center mb-3">
                    <button
                        onClick={() => onSignUp()}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        SIGN UP
                    </button>
                </div>
                <div className="flex justify-center items-center">
                    <div>
                        Already have an account?
                        <span className="font-medium text-base text-blue-800">
                            <Link to='/login'> LOG IN</Link>
                        </span>
                    </div>
                </div>
            </div>
        </div>

    )
}

export { Signup }