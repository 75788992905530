import { isValidString } from "./validity";

function isLoggedIn(user) {
    if (user) {
        if (isValidString(user.accessToken) && isValidString(user.refreshToken)) {
            return true;
        }
    }
    return false;
}

export { isLoggedIn };