// import axios from "axios";
import { setLoadingStatus, setMsgInfo, setErrorInfo } from "../../common/commonActions";
import { setUser } from "../../auth/authActions";
import ApiService from "../../../services/api-service";
import { whiteListedApiActions } from "../api/white-listed-api-actions";

export const api = ({ dispatch, getState }) => (next) => async (action) => {
    if (!whiteListedApiActions.includes(action.type)) return next(action);

    const { apiEndPoint, data = {}, method, successMsg, errorMsg, authorizationHeader = null } = action.payload;
    setLoadingStatus(true)(dispatch);

    try {
        const apiService = new ApiService(apiEndPoint);
        const res = await apiService.makeApiCall(method, JSON.stringify(data), authorizationHeader);

        action.payload = res.data;
        setLoadingStatus(false)(dispatch);
        setMsgInfo({ type: "success", msg: successMsg })(dispatch);
        setErrorInfo(null)(dispatch);

        if (action.type !== 'LOG_IN') {
            const user = getState().auth.user;
            if (res.data.user && user) {
                if (user.accessToken !== res.data.user.accessToken) {
                    setUser(res.data.user)(dispatch);
                }
            }
        }
    } catch (error) {
        action.payload = null;
        setLoadingStatus(false)(dispatch);
        setMsgInfo({ type: "error", msg: errorMsg })(dispatch);
        setErrorInfo({ type: error.type, msg: error.message })(dispatch);
        if (error.message.includes('Unauthorized! Refresh Token is expired!')) {
            setUser(null)(dispatch);
        }
    }

    next(action);
};

