import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch, useNavigate, useParams } from "react-router-dom";
import { useEffectOnce } from '../../hooks/use-effect-once';
import { resetAuthState, verifySignup } from '../../store/auth/authActions';
import { resetCommonState } from '../../store/common/commonActions';

const SignupConfirmation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { confirmationCode } = useParams();
  const user = useSelector(state => state.auth.user);
  const errorInfo = useSelector(state => state.common.errorInfo);
  const isSameRoute = useMatch('/verify-signup/:confirmationCode');

  useEffectOnce(() => {
    if (isSameRoute && confirmationCode) {
      dispatch(verifySignup(confirmationCode));
    }
  });

  function onGoToLoginPage() {
    dispatch(resetAuthState());
    dispatch(resetCommonState());
    navigate("/login");
  }

  return (
    <div className="w-full p-4">
      {user &&
        <div className="w-full">
          {user.status === 'registered' &&
            <div className="w-full flex flex-col justify-center align-center items-center">
              <h1 className="text-lg font-medium">Hi {user.firstName},</h1>
              <h1 className="text-md font-medium my-1">Welcome to Ahar Admin !</h1>
              <div className='mb-4'>Your Account is Confirmed</div>
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => onGoToLoginPage()}>
                Please Login
              </button>
            </div>
          }
          
          {user.status === 'pending' &&
            <div className="w-full flex flex-col justify-center align-center items-center">
              <h1 className="text-md font-medium my-1">Email Confirmation Required !</h1>
              <div>A mail was sent to your email addres. Please confirm.</div>
            </div>
          }

          {(user.status === 'banned' || user.status === 'onhold') &&
            <div className="w-full flex flex-col justify-center align-center items-center">
              <h1 className="text-lg font-medium">Nothing to show!</h1>
            </div>
          }
        </div>
      }
      {errorInfo &&
        <div className="w-full">
          {errorInfo.msg === "User already registered!" &&
            <div className="w-full flex flex-col justify-center align-center items-center">
              <div className='mb-3'>It seems you already verified your email before!</div>
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => onGoToLoginPage()}>
                Please Login
              </button>
            </div>
          }
          {errorInfo.msg !== "User already registered!" &&
            <div className="w-full flex flex-col justify-center align-center items-center">
              <h1 className="text-lg font-medium mb-1">Something Went Wrong!</h1>
              <div>Please try again later. If this problem still exists, please contact our support team.</div>
            </div>
          }
        </div>
      }
    </div>
  )
}

export { SignupConfirmation };
