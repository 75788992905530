import React from 'react'

const LoadingIndicator = () => {
    return (
        <div className='fixed top-0 bottom-0 left-0 right-0 w-full h-full z-index-100 opacity-50 bg-red-500'>
            <div className="flex justify-center items-center w-ful h-full">
                <h3 className='opacity-100'>loading....</h3>
            </div>
        </div>
    )
}

export { LoadingIndicator };