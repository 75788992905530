import * as categoryActionTypes from '../../category/categoryActionTypes';
import * as recipeActionTypes from '../../recipe/recipeActionTypes';
import * as roleActionTypes from '../../role/roleActionTypes';
import * as statusActionTypes from '../../status/statusActionTypes';
import { SIGN_UP, VERIFY_SIGN_UP, LOG_IN } from '../../auth/authActionTypes';

const authActionTypes = { SIGN_UP, VERIFY_SIGN_UP, LOG_IN };

let categoryActionTypesArr = Object.values(categoryActionTypes);
let recipeActionTypesArr = Object.values(recipeActionTypes);
let roleActionTypesArr = Object.values(roleActionTypes);
let statusActionTypesArr = Object.values(statusActionTypes);
let authActionTypesArr = Object.values(authActionTypes);

export const whiteListedApiActions = [
    ...categoryActionTypesArr,
    ...recipeActionTypesArr,
    ...roleActionTypesArr,
    ...statusActionTypesArr,
    ...authActionTypesArr
];