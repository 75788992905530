import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../store/auth/authActions';
import { isValidObject } from '../../utils/validity';
import { Link, useNavigate } from 'react-router-dom';

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const msgInfo = useSelector(state => state.common.msgInfo);
    const [warningMsg, setWarningMsg] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [credential, setCredential] = useState({
        email: "",
        password: ""
    });

    useEffect(() => {
        if (msgInfo) {
            if (msgInfo.type === 'success') {
                navigate("/");
            }
            return;
        }
        return;
    }, [msgInfo]);


    function handleUserInput(key, value) {
        setWarningMsg('');
        let prevCredential = { ...credential };
        prevCredential[key] = value;
        setCredential(prevCredential);
    }

    function onLogIn() {
        if (isValidObject(credential)) {
            dispatch(login(credential));
        } else {
            setWarningMsg('Please add all required information')
        }
    }

    return (
        <div className="w-full flex flex-col justify-center align-center p-4">
            <div className="flex justify-center align-center mb-4">
                <h1 className="text-lg font-medium">Login Page</h1>
            </div>
            <div className="flex justify-center items-center mb-3">
                <label
                    className="w-1/3 text-md font-medium text-gray-900">
                    Email
                </label>
                <input
                    value={credential.email}
                    onChange={(e) => handleUserInput('email', e.target.value)}
                    className=" w-2/3 p-2.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
                />
            </div>
            <div className="flex justify-center items-center mb-3">
                <label
                    className="w-1/3 text-md font-medium text-gray-900">
                    Password
                </label>
                <input
                    value={credential.password}
                    onChange={(e) => handleUserInput('password', e.target.value)}
                    className=" w-2/3 p-2.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
                    type={showPassword ? 'text' : 'password'}
                />
            </div>
            <div className="flex mb-3">
                <div className='w-1/3'></div>
                <div className="w-2/3">
                    <div className="flex justify-start items-center">
                        <label
                            className="mr-2 text-md font-sm text-gray-900">
                            Show Password
                        </label>
                        <input type="checkbox"
                            checked={showPassword}
                            onChange={(e) => setShowPassword(e.target.checked)}
                            className="accent-green-600"
                        />
                    </div>
                </div>
            </div>
            {warningMsg &&
                <div className="flex mb-3">
                    <div className='w-1/3'></div>
                    <div className="w-2/3 text-red-600 font-medium">
                        *Warning: {warningMsg}
                    </div>
                </div>
            }
            <div className="flex justify-center items-center mb-3">
                <button
                    onClick={() => onLogIn()}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    LOGIN
                </button>
            </div>
            <div className="flex justify-center items-center">
                <div>
                    Don't have an account?
                    <span className="font-medium text-base text-blue-800">
                        <Link to='/signup'> SIGN UP</Link>
                    </span>
                </div>
            </div>
        </div>
    )
}

export { Login }