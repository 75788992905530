import produce from "immer";
import { FETCH_ROLE_LIST, FETCH_ROLE, CREATE_ROLE, UPDATE_ROLE, DELETE_ROLE } from "./roleActionTypes";
import { initialState } from "./roleState";


const roleReducers = (state = initialState, action) => {
    const { type, payload } = action;
    return produce(state, draft => {
        switch (type) {
            case FETCH_ROLE_LIST:
                draft.list = payload ? payload.content : null;
                break;
            case FETCH_ROLE:
                draft.role = payload ? payload.content : null;
                break;
            case CREATE_ROLE:
                draft.role = payload ? payload.content : null;
                break;
            case UPDATE_ROLE:
                draft.role = payload ? payload.content : null;
                break;
            case DELETE_ROLE:
                draft.role = payload ? payload.content : null;
                break;
            default:

        }
    });
}

export default roleReducers;