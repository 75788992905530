import produce from "immer";
import { SIGN_UP, VERIFY_SIGN_UP, LOG_IN, LOG_OUT, RESET_AUTH_STATE, SET_USER } from "./authActionTypes";
import { intialState } from "./authState";

const authReducers = (state = intialState, action) => {
    const { type, payload } = action;
    return produce(state, draft => {
        switch (type) {
            case LOG_IN:
                draft.user = payload;
                break;
            case LOG_OUT:
                draft.user = payload.user;
                break;
            case SIGN_UP:
                draft.user = null;
                break;
            case VERIFY_SIGN_UP:
                draft.user = payload;
                break;
            case RESET_AUTH_STATE:
                draft.user = null;
                break;
            case SET_USER:
                draft.user = payload.user;
                break;
            default:
        }
    });
}

export default authReducers;