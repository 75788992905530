import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import React, { useState } from 'react';
import { generateRandomKey } from '../../../helpers/helper';
import { AddIngredientModal } from './add-ingredient-modal';
import { DeleteIngredientModal } from './delete-ingredient-modal';
import { EditIngredientModal } from './edit-ingredient-modal';
import { useDispatch } from 'react-redux';
import { setMsgInfo } from '../../../store/common/commonActions';

const Ingredients = ({ ingredientDetail, setIngredientDetail }) => {
    const dispatch = useDispatch();
    const [showAddIngredientModal, setShowAddIngredientModal] = useState(false);
    const [showEditIngredientModal, setShowEditIngredientModal] = useState(false);
    const [showDeleteIngredientModal, setShowDeleteIngredientModal] = useState(false);

    const [ingredientToProcess, setIngredientToProcess] = useState(null);

    function onEditIngredient(category, tempCategoryId, name, tempIngredientId, qty) {
        setIngredientToProcess({
            tempCategoryId: tempCategoryId,
            category: category,
            tempIngredientId: tempIngredientId,
            name: name,
            qty: qty
        })
        setShowEditIngredientModal(true);
    }

    function onDeleteIngredient(category, tempCategoryId, name, tempIngredientId, qty) {
        setIngredientToProcess({
            tempCategoryId: tempCategoryId,
            category: category,
            tempIngredientId: tempIngredientId,
            name: name,
            qty: qty
        })
        setShowDeleteIngredientModal(true);
    }

    function onSetIngredientDetail(detail, ref) {
        if (ref === "add-ingredient" || ref === "edit-ingredient" || ref === "delete-ingredient") {
            // console.log("from modal, detail : ", detail);
            detail = getIngredientDetails(detail, ref);
        }
        let newIngredientDetail = { ...ingredientDetail, ...detail };
        // console.log("newIngredientDetail : ", newIngredientDetail);
        setIngredientDetail(newIngredientDetail);
    }


    function getDataIndex(arr, key, value) {
        if (arr.length) {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i][key] === value) {
                    return i;
                }
            }
        }
        return -1;
    }

    function resetIngredientToProcess() {
        setIngredientToProcess({
            tempCategoryId: "",
            category: "",
            tempIngredientId: "",
            name: "",
            qty: ""
        })
    }

    function getIngredientDetails(details, ref) {
        let prevIngredientDetail = ingredientDetail;
        let ingredientList = prevIngredientDetail.ingredientList;
        let categoryPositionInIngredientList = -1;
        let ingredientPositionInIngredientArr = -1;

        if (ref === "add-ingredient") {
            categoryPositionInIngredientList = getDataIndex(ingredientList, 'category', details.category);
            if (categoryPositionInIngredientList >= 0) {
                ingredientPositionInIngredientArr = getDataIndex(ingredientList[categoryPositionInIngredientList].ingredients, 'name', details.name);
            }

            if (categoryPositionInIngredientList < 0 && ingredientPositionInIngredientArr < 0) {
                let ingredientListItem = { category: "", tempCategoryId: "", ingredients: [] };
                ingredientListItem.category = details.category;
                ingredientListItem.tempCategoryId = generateRandomKey('cat');
                ingredientListItem.ingredients.push(
                    {
                        name: details.name,
                        qty: details.qty,
                        tempIngredientId: generateRandomKey('ing')
                    }
                )
                ingredientList.push(ingredientListItem);
            } else if (categoryPositionInIngredientList >= 0 && ingredientPositionInIngredientArr < 0) {
                ingredientList[categoryPositionInIngredientList].ingredients.push({ name: details.name, qty: details.qty, tempIngredientId: generateRandomKey('ing') });
            } else if (categoryPositionInIngredientList >= 0 && ingredientPositionInIngredientArr >= 0) {
                dispatch(setMsgInfo({ type: 'warning', msg: `Ingredient "${details.name}" exists in category "${details.category}"` }));
            }
        } else {
            categoryPositionInIngredientList = getDataIndex(ingredientList, 'tempCategoryId', details.tempCategoryId);

            if (categoryPositionInIngredientList >= 0 && details.tempIngredientId) {
                ingredientPositionInIngredientArr = getDataIndex(ingredientList[categoryPositionInIngredientList].ingredients, 'tempIngredientId', details.tempIngredientId);
            }

            if (categoryPositionInIngredientList >= 0 && ingredientPositionInIngredientArr >= 0) {
                if (ref === "edit-ingredient") {
                    ingredientList[categoryPositionInIngredientList].category = details.category;
                    ingredientList[categoryPositionInIngredientList].ingredients.splice(ingredientPositionInIngredientArr, 1, { name: details.name, qty: details.qty, tempIngredientId: details.tempIngredientId });
                    resetIngredientToProcess();
                } else if (ref === "delete-ingredient") {
                    if (ingredientList[categoryPositionInIngredientList].ingredients.length === 1) {
                        ingredientList.splice(categoryPositionInIngredientList, 1);
                    } else {
                        ingredientList[categoryPositionInIngredientList].ingredients.splice(ingredientPositionInIngredientArr, 1);
                    }
                    resetIngredientToProcess();
                }
            }
        }

        // console.log("prevIngredientDetail : ", prevIngredientDetail);
        return prevIngredientDetail;
    }

    return (
        <div className="w-full p-2.5">
            <div className="w-full">
                <div className="w-full p-2.5 bg-sky-100">
                    <h2 className="text-blue-800 text-lg font-medium">Ingredients</h2>
                    <div className="w-full p-2.5 flex justify-between items-center mb-3">
                        <div className="flex justify-start items-center">
                            <label
                                className="mr-2 text-md font-medium text-gray-900">
                                Ingredients Have Breakdown
                            </label>
                            <input type="checkbox"
                                checked={ingredientDetail.ingredientsHaveCategory}
                                onChange={(e) => onSetIngredientDetail({ ingredientsHaveCategory: e.target.checked }, 'ingredients-have-category')}
                                className="accent-green-600"
                            />
                        </div>
                        <button
                            onClick={() => setShowAddIngredientModal(true)}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            + Ingredient
                        </button>
                    </div>
                    {ingredientDetail.ingredientList.length > 0 && <div className="w-full p-2.5 flex justify-between items-center mb-3">
                        <table className="w-full border-2 border-slate-50">
                            <thead>
                                <tr>
                                    <th className="border-2 border-slate-800 p-2.5 w-1/5">Ingredient Category</th>
                                    <th className="border-2 border-slate-800 p-2.5 w-1/5">Ingredient Name</th>
                                    <th className="border-2 border-slate-800 p-2.5 w-1/5">Ingredient Quantity</th>
                                    <th colSpan={2} className="border-2 border-slate-800 p-2.5 w-2/5">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ingredientDetail.ingredientList.map((listItem, i) => {
                                    return (
                                        <tr
                                            key={listItem.tempCategoryId}
                                        >
                                            <td

                                                className="border-2 border-slate-800 p-2.5 w-1/5"
                                            >
                                                <div className="w-full flex justify-center items-center">
                                                    {listItem.category}
                                                </div>
                                            </td>
                                            <td
                                                colSpan={4}
                                                className="border-2 border-slate-800 w-4/5">
                                                <table className="w-full">
                                                    <tbody className="w-full">
                                                        {listItem.ingredients.length > 0 && listItem.ingredients.map((ingredient, j) => {
                                                            return (
                                                                <tr
                                                                    key={ingredient.tempIngredientId}
                                                                    className="w-full border-2 border-slate-800"
                                                                >
                                                                    <td
                                                                        className="w-1/4 p-2.5 border-2 border-r-slate-800"
                                                                    >
                                                                        <div className="w-full flex justify-center items-center">
                                                                            {ingredient.name}
                                                                        </div>
                                                                    </td>
                                                                    <td
                                                                        className="w-1/4 p-2.5 border-2 border-r-slate-800"
                                                                    >
                                                                        <div className="w-full flex justify-center items-center">
                                                                            {ingredient.qty}
                                                                        </div>
                                                                    </td>
                                                                    <td className="w-1/4 p-2.5 border-2 border-r-slate-800" >
                                                                        <div className="w-full flex justify-center items-center">
                                                                            <PencilIcon
                                                                                onClick={() => onEditIngredient(listItem.category, listItem.tempCategoryId, ingredient.name, ingredient.tempIngredientId, ingredient.qty)}
                                                                                className="h-6 w-6 text-blue-500 cursor-pointer"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td className="w-1/4 p-2.5 border-2 border-l-slate-800" >
                                                                        <div className="w-full flex justify-center items-center">
                                                                            <TrashIcon
                                                                                onClick={() => onDeleteIngredient(listItem.category, listItem.tempCategoryId, ingredient.name, ingredient.tempIngredientId, ingredient.qty)}
                                                                                className="h-6 w-6 text-red-500 cursor-pointer"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>}
                </div>
                <AddIngredientModal
                    showAddIngredientModal={showAddIngredientModal}
                    setShowAddIngredientModal={setShowAddIngredientModal}
                    ingredientsHaveCategory={ingredientDetail.ingredientsHaveCategory}
                    onSetIngredientDetail={onSetIngredientDetail}
                    generateRandomKey={generateRandomKey}
                />
                <EditIngredientModal
                    showEditIngredientModal={showEditIngredientModal}
                    setShowEditIngredientModal={setShowEditIngredientModal}
                    ingredientsHaveCategory={ingredientDetail.ingredientsHaveCategory}
                    ingredientToProcess={ingredientToProcess}
                    onSetIngredientDetail={onSetIngredientDetail}
                />
                <DeleteIngredientModal
                    showDeleteIngredientModal={showDeleteIngredientModal}
                    setShowDeleteIngredientModal={setShowDeleteIngredientModal}
                    ingredientToProcess={ingredientToProcess}
                    onSetIngredientDetail={onSetIngredientDetail}
                />
            </div>
        </div>
    )
}

export { Ingredients };
