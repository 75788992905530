import produce from "immer";
import { FETCH_RECIPE_LIST, FETCH_RECIPE, CREATE_RECIPE, UPDATE_RECIPE, DELETE_RECIPE } from "./recipeActionTypes";
import { intialState } from "./recipeState";


const recipeReducers = (state = intialState, action) => {
    const { type, payload } = action;
    return produce(state, draft => {
        switch (type) {
            case FETCH_RECIPE_LIST:
                draft.list = payload ? payload.content : null;
                break;
            case FETCH_RECIPE:
                draft.recipe = payload ? payload.content : null;
                break;
            case CREATE_RECIPE:
                draft.recipe = payload ? payload.content : null;
                break;
            case UPDATE_RECIPE:
                draft.recipe = payload ? payload.content : null;
                break;
            case DELETE_RECIPE:
                draft.recipe = payload ? payload.content : null;
                break;
            default:

        }
    });
}

export default recipeReducers;