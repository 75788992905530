import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Ingredients } from '../../comps/recipe/ingredients/ingredients';
import { Instructions } from '../../comps/recipe/instructions/instructions';
import { Summary } from '../../comps/recipe/summary/summary';
import { setMsgInfo } from '../../store/common/commonActions';
import { isValidString } from '../../utils/validity';
import { createRecipe } from '../../store/recipe/recipeActions';

const NewRecipe = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const dispatch = useDispatch();
    const categories = state ? state.categories : [];
    const statuses = state ? state.statuses : [];

    // console.log("categories : ", categories);
    // console.log("statuses : ", statuses);

    const [summary, setSummary] = useState(getInitialSummary(categories, statuses));

    function getInitialSummary(categories, statuses) {
        if (categories.length && statuses.length) {
            return {
                status: statuses[0]._id,
                categoryId: categories[0]._id,
                subCategoryId: categories[0].subCategories[0]._id,
                name: "",
                totalCookingTime: "",
                totalServings: "",
                image: "",
                imageUrl: "",
                recipeSource: ""
            }
        }
        return {}
    }

    const [ingredientDetail, setIngredientDetail] = useState({
        ingredientsHaveCategory: false,
        ingredientList: []

    });

    const [instructionDetail, setInstructionDetail] = useState({
        instructionsHaveCategory: false,
        instructionList: []

    });

    const [recipe, setRecipe] = useState(
        {
            status: "draft",
            summary: summary,
            ingredientDetail: ingredientDetail,
            instructionDetail: instructionDetail
        }
    )

    useEffect(() => {
        changeRecipe();
    }, [summary, ingredientDetail, instructionDetail]);

    function onSubmit() {
        // console.log("initial fullRecipe : ", fullRecipe);
        let finalRecipe = { ...recipe };
        finalRecipe.ingredientDetail = getValidIngredientDetail(finalRecipe.ingredientDetail);
        finalRecipe.instructionDetail = getValidInstructionDetail(finalRecipe.instructionDetail);

        if (isValidRecipe(finalRecipe)) {
            // console.log("recipe is valid : ", finalRecipe);
            // console.log("recipe is valid : ", finalRecipe);
            dispatch(createRecipe(finalRecipe));
            resetData();
            navigate("/recipe");
        } else {
            dispatch(setMsgInfo({ type: "warning", msg: "Please add all necessary fields!" }))
        }
    }

    function getValidIngredientDetail(ingredientDetail) {
        for (let i = 0; i < ingredientDetail.ingredientList.length; i++) {
            let detailListItem = ingredientDetail.ingredientList[i];
            if (detailListItem.tempCategoryId) {
                delete detailListItem['tempCategoryId'];
            }
            let ingredientArr = detailListItem.ingredients;
            for (let j = 0; j < ingredientArr.length; j++) {
                let ingredient = ingredientArr[j];
                if (ingredient.tempIngredientId) {
                    delete ingredient['tempIngredientId']
                }
            }
        }
        return ingredientDetail;
    }

    function getValidInstructionDetail(instructionDetail) {
        for (let i = 0; i < instructionDetail.instructionList.length; i++) {
            let detailListItem = instructionDetail.instructionList[i];
            if (detailListItem.tempCategoryId) {
                delete detailListItem['tempCategoryId'];
            }
            let instructionArr = detailListItem.instructions;
            for (let j = 0; j < instructionArr.length; j++) {
                let instruction = instructionArr[j];
                if (instruction.tempInstructionId) {
                    delete instruction['tempInstructionId']
                }
            }
        }
        return instructionDetail;
    }

    function changeRecipe() {
        setRecipe(prevState => ({
            ...prevState,
            summary: summary,
            ingredientDetail: ingredientDetail,
            instructionDetail: instructionDetail
        }));
    }

    function isValidRecipe(recipe) {
        // console.log("recipe : ", recipe);
        // console.log("recipe : ", recipe);
        if (isValidString(recipe.summary.name)
            && isValidString(recipe.summary.totalCookingTime)
            && isValidString(recipe.summary.totalServings)
            && recipe.ingredientDetail.ingredientList.length
            && recipe.instructionDetail.instructionList.length
        ) {
            return true;
        }
        return false;
    }

    function resetData() {
        setSummary({
            categoryId: categories[0]._id,
            subCategoryId: categories[0].subCategories[0]._id,
            name: "",
            totalCookingTime: "",
            totalServings: "",
            image: "",
            imageUrl: "",
            recipeSource: ""

        });
        setIngredientDetail({
            ingredientsHaveCategory: false,
            ingredientList: []

        });
        setInstructionDetail({
            instructionsHaveCategory: false,
            instructionList: []

        });
        setRecipe({
            status: "draft",
            summary: summary,
            ingredientDetail: ingredientDetail,
            instructionDetail: instructionDetail
        });
    }

    return (
        <div className="w-full p-2.5">
            <div className="flex justify-center items-center">
                <h2 className="text-cyan-800 text-lg font-medium">Create New Recipe</h2>
            </div>
            <div>
                {(categories.length > 0 && statuses.length > 0) &&
                    <div className="w-full">
                        <div className="w-full">
                            <Summary
                                summary={summary}
                                setSummary={setSummary}
                                categories={categories}
                                statuses={statuses} />
                        </div>
                        <div className="w-full">
                            <Ingredients
                                ingredientDetail={ingredientDetail}
                                setIngredientDetail={setIngredientDetail} />
                        </div>
                        <div className="w-full">
                            <Instructions
                                instructionDetail={instructionDetail}
                                setInstructionDetail={setInstructionDetail} />
                        </div>
                        <div className="w-full flex justify-center items-center mt-5">
                            <button
                                className="text-blue-500 background-transparent font-bold uppercase px-6 py-3 rounded text-sm mr-2 mb-1 border border-blue-500"
                                type="button"
                                onClick={() => navigate("/recipe")}
                            >
                                CANCEL
                            </button>
                            <button
                                onClick={() => onSubmit()}
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                CREATE
                            </button>
                        </div>
                    </div>
                }

                {categories.length < 1 &&
                    <div className="flex justify-center items-center">
                        <h3 className="text-cyan-800 text-md text-orange-500 font-medium">Please create necessary categories.</h3>
                    </div>
                }

                {statuses.length < 1 &&
                    <div className="flex justify-center items-center">
                        <h3 className="text-cyan-800 text-md text-orange-500 font-medium">Please create necessary statuses.</h3>
                    </div>
                }
            </div>
        </div >
    )
}

export { NewRecipe };
